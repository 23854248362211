<template>
  <div class="" id="schedule">
    <Preloader v-if="!loaded" />
    <div class="container-fluid">
      <div class="row p-2">
        <div class="col-12" v-html="pageContent"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Preloader from "../components/Preloader";
export default {
  name: "Schedule.vue",
  components:{
    Preloader,
  },
  data(){
    return {
      loaded:false,
      pageContent:null
    }
  },
  created() {
    this.$http.get("schedule").then(function (response) {
      console.log(response.body);
      if (response.body.success){
        this.pageContent = response.body.schedule.content;
      }
      this.loaded=true;
    })
  }
}
</script>

<style scoped>

</style>
